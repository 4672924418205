import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => {
  return (
    <div className="card cardBorder">
      <img
        loading="lazy"
        src={`/icons/${post.frontmatter.city}.svg`}
        alt={post.frontmatter.city}
        width="100px"
        height="100px"
      />
      <h2>
        <Link to={post.frontmatter.path}>{post.frontmatter.city}</Link>
      </h2>
    </div>
  )
}
export default PostLink
